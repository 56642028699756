<template>
    <div class="version">
      <p class="version__text text">$ver.vs.0.2.22</p>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="less" scoped>
  .version {
    position: absolute;
    top: 16px;
    left: 16px;
    &__text {
    font-size: 16px;
  }
  }
  
  </style>