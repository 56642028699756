import { createRouter, createWebHashHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
// import SmsView from "../views/SmsView.vue";
import UserView from "../views/UserView.vue";
import ScannerView from "../views/ScannerView.vue";
import GuestView from "../views/GuestView.vue";
// import GuestСallView from "../views/GuestCallView.vue";
// import UserСallView from "../views/UserCallView.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  // {
  //   path: "/sms",
  //   name: "sms",
  //   component: SmsView,
  // },
  {
    path: "/user",
    name: "user",
    component: UserView,
  },
  {
    path: "/scanner",
    name: "scanner",
    component: ScannerView,
  },
  {
    path: "/guest",
    name: "guest",
    component: GuestView,
  },
  // {
  //   path: "/guestcall",
  //   name: "guestcall",
  //   component: GuestСallView,
  // },
  // {
  //   path: "/usercall",
  //   name: "usercall",
  //   component: UserСallView,
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
