<template>
  <header class="header">
    <div class="header__logo">
      <img src="../assets/logo.png" alt="QRCOM logo" />
    </div>
    <h1 class="Header__title">QRCOM</h1>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="less" scoped>
.header {
  padding-top: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__logo {
    width: 50px;
    height: 50px;
    margin-bottom: 8px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
