<template>
  <div class="guest">
    <!-- <Header /> -->
    <section class="guest__section">
      <p class="guest__text">{{ site.street }} {{ site.house }}</p>
      <p class="guest__text">подъезд {{ site.entrance }}</p>
      <p class="guest__text">квартиры 1 - 15</p>
      <p class="guest__input">{{ ui.input }}</p>
      <div class="guest__numpad numpad">
        <div class="numpad__row">
          <button class="numpad__button button" @click="pushNumber(1)">1</button>
          <button class="numpad__button button" @click="pushNumber(2)">2</button>
          <button class="numpad__button button" @click="pushNumber(3)">3</button>
        </div>
        <div class="numpad__row">
          <button class="numpad__button button" @click="pushNumber(4)">4</button>
          <button class="numpad__button button" @click="pushNumber(5)">5</button>
          <button class="numpad__button button" @click="pushNumber(6)">6</button>
        </div>
        <div class="numpad__row">
          <button class="numpad__button button" @click="pushNumber(7)">7</button>
          <button class="numpad__button button" @click="pushNumber(8)">8</button>
          <button class="numpad__button button" @click="pushNumber(9)">9</button>
        </div>
        <div class="numpad__row">
          <!-- <button class="guest__button button" @click="clear()">
            <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
            <img src="../assets/icons/x-circle.svg" alt="Del" />
          </button> -->
          <div class="numpad__dummy"></div>
          <button class="numpad__button button" @click="pushNumber(0)">0</button>
          <button class="numpad__button button" @click="clear()">
            <font-awesome-icon icon="fa-solid fa-delete-left" />
            <!-- <img src="../assets/icons/phone-call.svg" alt="Call" /> -->
          </button>
        </div>
      </div>
      <button class="guest__button button" @click="requestCall()">
        <font-awesome-icon icon="fa-solid fa-phone" />
      </button>
    </section>
    <div class="guestcall" v-show="videoIsVisible">
      <section class="guestcall__section">
        <h2 class="guestcall__title title">{{ title }}</h2>
        <div class="guestcall__container">
          <video class="guestcall__video" autoplay playsinline></video>
          <video class="guestcall__audio" playsinline></video>
        </div>
        <button class="guestcall__button-red button-round" @click="endCall()">
          <img src="../assets/icons/phone-off.svg" alt="" />
        </button>
      </section>
    </div>
  </div>
</template>

<script>
// let lastPeerId = null;
let peer = null; // own peer object
let currentConnection = null;
let currentCall = null;
let tracks = null;
import Header from "@/components/Header.vue";
export default {
  name: "GuestView",
  components: {
    Header,
  },
  data() {
    return {
      site: {
        type: null,
        street: null,
        house: null,
        entrance: null,
        startFlat: null,
        endFlat: null,
      },
      ui: {
        input: "0000",
      },
      peerId: null,
      token: null,
      videoIsVisible: false,
      title: "Ожидание соединения",
    };
  },
  mounted() {
    // CHECK DATA ABOUT OBJECT
    if (localStorage.getItem("currentSite") !== null) {
      this.site = JSON.parse(localStorage.getItem("currentSite"));
      console.log(`<QRCOM> App: site data received`);
      console.log(this.site);
    } else this.$router.push("scanner");
  },
  methods: {
    // NUMPAD
    pushNumber(number) {
      let numArr = this.ui.input.split("");
      if (numArr[0] == 0) {
        numArr.push(number);
        numArr.shift();
      }
      this.ui.input = numArr.join("");
    },
    clear() {
      this.ui.input = "0000";
    },
    back() {
      this.stopStreaming();
      this.$router.push("/");
    },
    requestCall() {
      console.log("<QRCOM> App: Request call from owner");
      console.log("<QRCOM> App: Try to get video stream");

      this.title = "Ожидание соединения"
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then((localVideoStream) => {
          const localVideo = document.querySelector(".guestcall__video");

          console.log("<QRCOM> App: Get video stream");

          localVideo.srcObject = localVideoStream;
          localVideo.muted = true;
          tracks = localVideoStream.getTracks();
          this.videoIsVisible = true;
          this.initializePeer(localVideoStream);
        })
        .catch((err) => {
          console.log("Failed to get local stream:", err);
        });
    },

    // PEERJS
    initializePeer(localStream) {
      console.log("<QRCOM> App: Try to connect to PeerJS")
      peer = new Peer();

      peer.on("open", (id) => {
        console.log(`<QRCOM> PeerJS: Connected to seerver. ID received ${id}`);
        this.peerId = id;
        this.requestToken();
      });

      peer.on("connection", (connection) => {
        // Allow only a single connection
        if (currentConnection && currentConnection.open) {
          connection.on("open", () => {
            connection.send("<QRCOM> PeerJS: Already connected to another client");
            setTimeout(() => {
              connection.close();
            }, 500);
          });
          return;
        }

        currentConnection = connection;
        console.log(`QRCOM: Connected to ${currentConnection.peer}`);
        currentConnection.on("data", (data) => {
          console.log(`<QRCOM> PeerJS: Data received (${data})`);
          if (data == "qrcom-accept-call") {
            this.receiveAcceptCall();
          }
          if (data == "qrcom-open-door") {
            this.receiveOpenDoor()
          }
          if (data == "qrcom-close-call") {
            this.endCall()
          }
        });
        currentConnection.on("close", () => {
          currentConnection = null;
          // this.endCall();
          console.log("QRCOM: Connection closed");
        });
      });

      peer.on("call", (call) => {
        console.log(`<QRCOM> PeerJS: Incoming call from ${call.peer}`);
        currentCall = call;
        call.answer(localStream);
        call.on("stream", (remoteStream) => {
          console.log("<QRCOM> PeerJS: Answer with video stream")
          const remoteAudio = document.querySelector(".guestcall__audio");
          remoteAudio.srcObject = remoteStream;
          this.title = "Ожидание ответа";
        });

        
        // navigator.mediaDevices
        //   .getUserMedia({
        //     video: true,
        //     audio: true,
        //   })
        //   .then((localStream) => {
        //     console.log("<QRCOM> App: Get user stream");
        //     tracks = localStream.getTracks();
        //     const localVideo = document.querySelector(".guestcall__video");
        //     localVideo.srcObject = localStream;
        //     // localVideo.muted = "muted";
        //     localVideo.muted = true;
        //     call.answer(localStream);
        //     currentCall = call;

        //     call.on("stream", (remoteStream) => {
        //       const remoteAudio = document.querySelector(".guestcall__audio");
        //       remoteAudio.srcObject = remoteStream;
        //       // remoteAudio.muted = "muted";
        //       this.title = "Ожидание ответа";
        //     });

        //     call.on("data", (data) => { });
        //   })
        //   .catch((err) => {
        //     console.log("Failed to get local stream:", err);
        //   });
      });
      // peer.on("call", (call) => {
      //   console.log(`<QRCOM> PeerJS: Incoming call from ${call.peer}`);
      //   console.log("<QRCOM> App: Try to get user stream");
      //   navigator.mediaDevices
      //     .getUserMedia({
      //       video: true,
      //       audio: true,
      //     })
      //     .then((localStream) => {
      //       console.log("<QRCOM> App: Get user stream");
      //       tracks = localStream.getTracks();
      //       const localVideo = document.querySelector(".guestcall__video");
      //       localVideo.srcObject = localStream;
      //       // localVideo.muted = "muted";
      //       localVideo.muted = true;
      //       call.answer(localStream);
      //       currentCall = call;

      //       call.on("stream", (remoteStream) => {
      //         const remoteAudio = document.querySelector(".guestcall__audio");
      //         remoteAudio.srcObject = remoteStream;
      //         // remoteAudio.muted = "muted";
      //         this.title = "Ожидание ответа";
      //       });

      //       call.on("data", (data) => { });
      //     })
      //     .catch((err) => {
      //       console.log("Failed to get local stream:", err);
      //     });
      // });

      // peer.on("disconnected", () => {
      //   console.log("Connection lost. Please reconnect");
      //   // Workaround for peer.reconnect deleting previous id
      //   peer.id = lastPeerId;
      //   peer._lastServerId = lastPeerId;
      //   peer.reconnect();
      // });

      peer.on("close", () => {
        currentConnection = null;
        console.log("Connection destroyed");
      });

      peer.on("error", (err) => {
        console.log(err);
      });
    },
    receiveAcceptCall() {
      const remoteAudio = document.querySelector(".guestcall__audio");
      this.title = "Разговор";
      remoteAudio.play();
      console.log(remoteAudio)
      console.log("play remote stream");

    },
    receiveOpenDoor() {
      if (typeof ble !== "undefined") {
        document.addEventListener(
          "deviceready",
          () => {
            ble.scan(
              [],
              5,
              (device) => {
                if (device.name == "qrcom") {
                  ble.stopScan();
                  const deviceId = device.id;
                  ble.connect(
                    deviceId,
                    () => {
                      let serviceUuid =
                        "4fafc201-1fb5-459e-8fcc-c5c9c331914b";
                      let characteristicUuid =
                        "beb5483e-36e1-4688-b7f5-ea07361b26a8";
                      ble.write(
                        deviceId,
                        serviceUuid,
                        characteristicUuid,
                        this.convertString("0411"),
                        () => {
                          ble.disconnect(deviceId);
                          this.title = "Дверь открыта";
                          currentConnection.send("success");
                        },
                        () => console.log("WRITE ERROR")
                      );
                    },
                    () => console.log(`${deviceId} DISCONNECT`)
                  );
                }
              },
              () => console.log("SCAN ERROR")
            );
          },
          false
        );
      } else console.log("CORDOVA IS NEEDED");
    },
    endCall() {
      if(currentConnection) {
        currentConnection.send("qrcom-close-call");
        currentConnection = null;
      }
      this.videoIsVisible = false;
      this.stopStreaming();
      // this.closeConnection()
      
    },

    openDoor() {
      if (typeof ble !== "undefined") {
        document.addEventListener(
          "deviceready",
          () => {
            ble.scan([], 5,
              (device) => {
                if (device.name == "qrcom") {
                  ble.stopScan();
                  const deviceId = device.id;
                  ble.connect(deviceId,
                    () => {
                      let serviceUuid =
                        "4fafc201-1fb5-459e-8fcc-c5c9c331914b";
                      let characteristicUuid =
                        "beb5483e-36e1-4688-b7f5-ea07361b26a8";
                      ble.write(
                        deviceId,
                        serviceUuid,
                        characteristicUuid,
                        this.convertString("0411"),
                        () => {
                          ble.disconnect(deviceId);
                          this.title = "Дверь открыта";
                          currentConnection.send("success");
                        },
                        () => console.log("WRITE ERROR")
                      );
                    },
                    () => console.log(`${deviceId} DISCONNECT`)
                  );
                }
              },
              () => console.log("SCAN ERROR")
            );
          },
          false
        );
      } else console.log("CORDOVA IS NEEDED");
    },

    // HELPERS
    convertString(string) {
      let array = new Uint8Array(string.length);
      for (let i = 0, l = string.length; i < l; i++) {
        array[i] = string.charCodeAt(i);
      }
      return array.buffer;
    },
    stopStreaming() {
      if (tracks) {
        console.log(tracks)
        tracks.forEach((track) => {
          track.stop();
        });
      }
    },
    closeConnection() {
      if (currentConnection) {
        currentConnection.close();
        currentConnection = null;
      }
      if (currentCall) {
        // currentCall.close();
        currentCall = null;
      }
    },
    requestToken() {
      // const input = this.input;
      const url = "https://red-dev.qrcom.app/api/user/getToken";
      const data = {
        street: this.site.street,
        house: this.site.house,
        entrance: this.site.entrance,
        flat: parseInt(this.ui.input),
      };

      console.log(`QRCOM: Request token for ${data.street} ${data.house}-${data.flat}`)
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.text())
        .then((text) => {
          if (JSON.parse(text).token) {
            console.log("QRCOM: Backend response");
            console.log(JSON.parse(text));
            this.token = JSON.parse(text).token;
            this.sendPush();
          }
        })
        .catch((err) => console.error(`QRCOM: Token request error (${err})`));
    },
    sendPush() {
      const url = "https://fcm.googleapis.com/fcm/send";
      const auth = "key=AAAAgEhxj-I:APA91bG2EyWytJCLlDfXLvQZ5jEaOxcSjEQybpXZRk_-X1IwQY0onHJuxBr-8QHeD0l1djLtq6DGYbptPTa33n2vZ3v5rGKvBWu82DdrNMhot-qO-PghB9DCFnOZ1VyMbh9pYaV8OpwK"
      const data = {
        notification: {
          title: "QRCOM",
          body: "Звонок в домофон",
        },
        data: {
          peerId: this.peerId,
        },
        to: this.token,
      };

      console.log(`QRCOM: Send push to ${this.token}`)
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": auth
        },
      })
        .then((response) => response.text())
        .then((text) => {
          if (JSON.parse(text).token) {
            console.log("QRCOM: Backend response");
            console.log(JSON.parse(text));

          }
        })
        .catch((err) => console.error(`QRCOM: Push sending error (${err})`));
    },
  },
};
</script>

<style lang="less" scoped>
.guest {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__section {
    padding: 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & *:nth-child(7) {
      margin-top: 16px;
    }
  }

  &__text {
    font-size: 24px;
  }

  &__input {
    padding: 16px;
    font-size: 64px;
  }

  &__container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__audio {
    width: 0;
    height: 0;
    position: absolute;
  }

  &__button-red {
    position: absolute;
    bottom: 15%;
    background-color: red;
  }
}

.numpad {
  width: 100%;
  height: 304px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__dummy {
    width: 30%;
    height: 80px;
  }

  &__button {
    width: 30%;
    height: 64px;
  }
}

.guestcall {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;

  &__section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    position: relative;
  }

  &__title {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 64px;
    left: 0;
    z-index: 10;
  }

  &__container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__audio {
    width: 0;
    height: 0;
    position: absolute;
  }

  &__button-red {
    position: absolute;
    bottom: 15%;
    background-color: red;
  }
}
</style>
