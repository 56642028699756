<template>
  <div class="login">
    <!-- <Header /> -->
    <p class="login__text text">
      Введите номер телефона для входа в формате<br />7-7XX-XXX-XX-XX
    </p>
    <form class="login__form" @submit.prevent="loginAsUser(userId)">
      <input class="login__input input" type="tel" minlength="15" maxlength="15" v-model="ui.userIdText" required
        pattern="[0-9]{1}-[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}" @keydown="setInputMask" />
      <p class="login__error error">{{ ui.error }}</p>
      <button class="login__button button" type="submit">
        Войти
      </button>
    </form>
    <button class="login__button button" @click="loginAsGuest()">
      Войти как гость
    </button>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  name: "LoginView",
  components: {
    Header,
  },
  data() {
    return {
      ui: {
        userIdText: "7-7",
        error: null,
      }
    };
  },
  computed: {
    userId() {
      return this.ui.userIdText.split("-").join("");
    }
  },
  created() {
    this.checkSavedData();
  },
  methods: {
    setInputMask(event) {
      if (this.ui.userIdText.length < 4) this.ui.userIdText = "7-7";
      if ((this.ui.userIdText.length == 5
        || this.ui.userIdText.length == 9
        || this.ui.userIdText.length == 12)
        && event.key != "Backspace") this.ui.userIdText += "-";
    },

    checkSavedData() {
      if (localStorage.getItem("id") !== null) {
        console.log(`<QRCOM> App: Login with saved id as ${localStorage.getItem("id")}`);
        this.loginAsUser(localStorage.getItem("id"));
      }
    },

    loginAsUser(id) {
      const url = "https://red-dev.qrcom.app/api/user/login";
      const data = { userId: id };

      console.log("<QRCOM> App: Submit login data to backend");

      fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.text())
        .then((text) => {
          const response = JSON.parse(text);

          console.log("<QRCOM> App: Backend response received");
          console.log(response);

          if (response.state == "pass") {
            const userData = response.data;

            this.ui.userId = response.data.id;
            this.saveUserToLocalStorage(userData);
            console.log(`<QRCOM> App: Login as ${userData.id}`);
            this.$router.push("user");
          };

          if (response.state == "fail") {
            this.ui.error = response.reason;
          }
        })
        .catch((err) => console.error(`<QRCOM> App: Request error (${err})`));
    },

    loginAsGuest() {
      localStorage.clear();
      console.log("<QRCOM> App: Login as guest");
      this.$router.push("scanner");
    },

    saveUserToLocalStorage(user) {
      localStorage.setItem("id", user.id);
      localStorage.setItem("street", user.street);
      localStorage.setItem("house", user.house);
      localStorage.setItem("entrance", user.entrance);
      localStorage.setItem("flat", user.flat);
      console.log("<QRCOM> App: Save user data to local storage");
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // &__form {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  // }

  &__input {
    margin-bottom: 16px;
  }

  &__text {
    margin-bottom: 16px;
  }

  &__error {
    margin-bottom: 16px;
  }

  &__button {
    margin-bottom: 16px;
  }

  & > &__button {
    margin-bottom: 0px;
  }
}
</style>
