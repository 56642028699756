<template>
  <div class="scanner">
    <section class="scanner__section">
      <Scanner />
      <div class="scanner__mask mask">
        <div class="mask-top">
          <h2 class="scanner__title title">Отсканируйте QR</h2>
        </div>
        <div class="mask-middle">
          <div class="mask-left"></div>
          <div class="scanner__target"></div>
          <div class="mask-right"></div>
        </div>
        <div class="mask-bottom"></div>
      </div>
      <button class="scanner__button button" @click="imit()">$imitation</button>
    </section>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Scanner from "@/components/Scanner.vue";
export default {
  name: "ScannerView",
  components: {
    Header,
    Scanner,
  },
  methods: {
    imit() {
      localStorage.setItem(
        "currentSite",
        '{"type":"qrcom", "street":"Сарыарка", "house":31, "entrance":1, "startFlat":1, "endFlat":15}'
      );
      this.$router.push("guest");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/styles/vars.less");

.scanner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    color: @color-primary;
  }

  &__button {
    position: absolute;
    bottom: 16px;
    width: 50%;
  }

  &__target {
    width: 250px;
    height: 250px;
    border: solid 4px @color-primary;
  }

  &__mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    &>.mask-top {
      width: 100%;
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(5px);
    }

    &>.mask-middle {
      width: 100%;
      height: 250px;
      display: flex;

      &>.mask-left {
        width: calc((100% - 250px) / 2);
        background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(5px);
      }

      &>.mask-right {
        width: calc((100% - 250px) / 2);
        background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(5px);
      }
    }

    &>.mask-bottom {
      width: 100%;
      flex-grow: 1;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(5px);
    }
  }
}
</style>
