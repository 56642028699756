<template>
  <div class="user">
    <!-- <Header /> -->
    <section class="user__section">
      <p class="user__text text">
        {{ user.street }} {{ user.house }}<br />квартира {{ user.flat }}
      </p>
      <button class="user__button button" @click="openLocalDoor()">
        Открыть
      </button>
      <div class="user__container" v-show="videoIsVisible">
        <video class="user__video" autoplay playsinline></video>
        <div class="user__buttons">
          <button class="user__button-green button-round" @click="answer()">
            <img src="../assets/icons/phone-call.svg" alt="" />
          </button>
          <button class="user__button-blue button-round" @click="openDoor()">
            <img src="../assets/icons/unlock.svg" alt="" />
          </button>
          <button class="user__button-red button-round" @click="endCall()">
            <img src="../assets/icons/phone-off.svg" alt="" />
          </button>
        </div>
      </div>
    </section>
    <Navbar />
  </div>
</template>

<script>
let lastPeerId = null;
let peer = null; // Own peer object
// let peerId = null;
let currentConnection = null;
let currentCall = null;
let tracks = null;

import Header from "@/components/Header.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  name: "UserView",
  components: {
    Header,
    Navbar,
  },
  data() {
    return {
      videoIsVisible: false,
      user: {
        id: null,
        street: null,
        house: null,
        entrance: null,
        flat: null,
      },
    };
  },
  beforeMount() {
    if (localStorage.getItem("id") !== null) {
      console.log("<QRCOM> App: Load user data from local storage")
      this.user = {
        id: localStorage.getItem("id"),
        street: localStorage.getItem("street"),
        house: localStorage.getItem("house"),
        entrance: localStorage.getItem("entrance"),
        flat: localStorage.getItem("flat"),
      };
    } else this.$router.push("/");
  },
  mounted() {
    this.initializeFirebase();
    // this.initializePeer();
  },
  methods: {
    // FIREBASE
    initializeFirebase() {
      if (typeof FirebasePlugin !== "undefined") {
        FirebasePlugin.getToken(
          (fcmToken) => {
            console.log(`<QRCOM> Firebase: Get token ${fcmToken}`);
            // this.submitToken(this.user.id, fcmToken);
          },
          (error) => console.error(`<QRCOM> Firebase: Token error (${error})`)
        );
        FirebasePlugin.onTokenRefresh(
          (fcmToken) => {
            console.log(`<QRCOM> Firebase: Refresh token ${fcmToken}`);
            this.submitToken(this.user.id, fcmToken);
          },
          (error) => console.error(`<QRCOM> Firebase: Token error (${error})`)
        );
        FirebasePlugin.onMessageReceived((message) => {
          console.log(`<QRCOM> Firebase: Message received`);
          console.log(message);
          if (message.peerId && message.tap) {
            console.log("<QRCOM> Firebase: Receive background push")
            this.initializePeer(message.peerId);
          } else if (message.peerId) {
            console.log("<QRCOM> Firebase: Receive push")
            this.initializePeer(message.peerId);
          }
        }, (error) => {
          console.error(error);
        });
      } else console.log("<QRCOM> Firebase: Running in browser. Push is not enabled");
    },

    // PEERJS
    initializePeer(peerId) {
      peer = new Peer();

      peer.on("open", (id) => {
        console.log(`<QRCOM> PeerJS: ID received ${id}`);
        this.connectToPeerId(peerId);
      });
      // peer.on("connection", (connection) => {
      //   // Disallow incoming connections
      //   connection.on("open", () => {
      //     connection.send("User does not accept incoming connections");
      //     setTimeout(() => {
      //       connection.close();
      //     }, 500);
      //   });
      // });

      peer.on("connection", (connection) => {
        // Allow only a single connection
        if (currentConnection && currentConnection.open) {
          connection.on("open", () => {
            connection.send("<QRCOM> PeerJS: Already connected to another client");
            setTimeout(() => {
              connection.close();
            }, 500);
          });
          return;
        }

        currentConnection = connection;
        console.log(`<QRCOM> PeerJS: Connected to ${currentConnection.peer}`);
        currentConnection.on("data", (data) => {
          console.log(`<QRCOM> PeerJS: Data received (${data})`);
          if (data == "qrcom-close-call") {
            this.endCall();
          }
        });
        currentConnection.on("close", () => {
          currentConnection = null;
          this.endCall();
          console.log("<QRCOM> PeerJS: Connection closed");
        });
      });

      // peer.on("disconnected", () => {
      //   console.log("Connection lost. Please reconnect");
      //   // Workaround for peer.reconnect deleting previous id
      //   peer.id = lastPeerId;
      //   peer._lastServerId = lastPeerId;
      //   peer.reconnect();
      // });

      peer.on("close", () => {
        currentConnection = null;
        // this.endCall();
        console.log("<QRCOM> PeerJS: Peer closed");
      });

      peer.on("error", (err) => {
        console.log(`<QRCOM> PeerJS: Connection error ${err}`);
      });
    },
    connectToPeerId(id) {
      // Close old connection
      if (currentConnection) {
        currentConnection.close();
      }
      // Create connection to destination peer specified in the input field
      if (id != null) {
        console.log(`<QRCOM> PeerJS: Try connect to ${id}`);
        currentConnection = peer.connect(id, {
          reliable: true,
        });

        currentConnection.on("open", () => {
          console.log(`<QRCOM> PeerJS: Connected to ${currentConnection.peer}`);
          this.call(id);
        });
        // Handle incoming data (messages only since this is the signal sender)
        currentConnection.on("data", (data) => {
          // if (data == "open") {
          //   if (typeof ble !== "undefined") {
          //     document.addEventListener(
          //       "deviceready",
          //       () => {
          //         ble.scan(
          //           [],
          //           5,
          //           (device) => {
          //             if (device.name == "qrcom") {
          //               ble.stopScan();
          //               const deviceId = device.id;
          //               ble.connect(
          //                 deviceId,
          //                 () => {
          //                   let serviceUuid =
          //                     "4fafc201-1fb5-459e-8fcc-c5c9c331914b";
          //                   let characteristicUuid =
          //                     "beb5483e-36e1-4688-b7f5-ea07361b26a8";
          //                   ble.write(
          //                     deviceId,
          //                     serviceUuid,
          //                     characteristicUuid,
          //                     this.convertString("0411"),
          //                     () => {
          //                       ble.disconnect(deviceId);
          //                       this.title = "Дверь открыта";
          //                       currentConnection.send("success");
          //                     },
          //                     () => console.log("WRITE ERROR")
          //                   );
          //                 },
          //                 () => console.log(`${deviceId} DISCONNECT`)
          //               );
          //             }
          //           },
          //           () => console.log("SCAN ERROR")
          //         );
          //       },
          //       false
          //     );
          //   } else console.log("CORDOVA IS NEEDED");
          // }
          // if (data == "endCall") this.endCall();
          // if (data == "answer") {
          //   const title = document.querySelector(".guestcall__title");
          //   this.title = "Связь установлена";
          //   remoteStream.play();
          //   console.log("play remote stream");
          // }
        });
        currentConnection.on("close", () => {
          this.endCall();
          console.log(`<QRCOM> PeerJS: Connection closed`);
        });
      } else {
        console.log("<QRCOM> PeerJS: Id is not set");
      }
    },
    call(id) {
      // TODO: CHANGE TO VUE
      const remoteVideo = document.querySelector(".user__video");

      console.log("<QRCOM> App: Try to get user stream");
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then((localStream) => {
          console.log("<QRCOM> App: Get user stream");
          tracks = localStream.getTracks();
          // const localVideo = document.querySelector(".guestcall__video");
          // localVideo.srcObject = localStream;
          // localVideo.play();
          // localVideo.muted = "muted";

          const call = peer.call(id, localStream);
          console.log(`<QRCOM> PeerJS: Call to ${id}`);
          call.on("stream", (remoteStream) => {
            console.log(`<QRCOM> PeerJS: Stream received from ${id}`)
            remoteVideo.srcObject = remoteStream;
            this.videoIsVisible = true;
          });
          // call.on("error", (err) => console.log(err));
          call.on("close", () => {
            console.log("<QRCOM> PeerJS: Call closed");
            this.endCall();
          });
          currentCall = call;
        })
        .catch((err) => {
          console.log(`<QRCOM>: Failed to get user stream (${err})`);
        });
    },

    // USER ACTIONS
    answer() {
      currentConnection.send("qrcom-accept-call");
    },
    openDoor() {
      currentConnection.send("qrcom-open-door");
    },
    openLocalDoor() {
      console.log("<QRCOM> App: Button was pushed");
      if (typeof ble !== "undefined") {
        console.log("<QRCOM> BLE: Ok");
        document.addEventListener(
          "deviceready",
          () => {
            console.log("<QRCOM> BLE: Start scanning");
            ble.scan(
              [],
              5,
              (device) => {
                if (device.name == "qrcom") {
                  console.log("<QRCOM> BLE: Qrcom device found");
                  ble.stopScan();
                  const deviceId = device.id;
                  console.log(`<QRCOM> BLE: Try to connect to ${deviceId}`);
                  ble.connect(
                    deviceId,
                    (data) => {
                      console.log("<QRCOM> BLE: Conneted to qrcom device, try to transfer data");
                      console.log(data);
                      const serviceUuid =
                        "4fafc201-1fb5-459e-8fcc-c5c9c331914b";
                      const characteristicUuid =
                        "beb5483e-36e1-4688-b7f5-ea07361b26a8";
                      ble.write(
                        deviceId,
                        serviceUuid,
                        characteristicUuid,
                        this.convertString("0411"),
                        () => {
                          console.log("<QRCOM> BLE: Data transfer successful");
                          ble.disconnect(deviceId);
                          alert("Дверь открыта");
                        },
                        () => console.log("<QRCOM> BLE: Data transfer error")
                      );
                    },
                    (data) => {
                      console.log(`<QRCOM> BLE: qrcom device disconnected (${deviceId})`)
                      console.log(data)
                      }
                  );
                }
              },
              () => console.log("<QRCOM> BLE: Scan error")
            );
          },
          false
        );
      } else console.log("<QRCOM>: Running in browser, ble is not available");
    },
    endCall() {
      if (currentConnection) {
        currentConnection.send("qrcom-close-call");
        currentConnection = null;
      }
      this.videoIsVisible = false;
      this.stopStreaming();
      // this.closeConnection()

    },

    //HELPERS
    convertString(string) {
      let array = new Uint8Array(string.length);
      for (let i = 0, l = string.length; i < l; i++) {
        array[i] = string.charCodeAt(i);
      }
      return array.buffer;
    },
    submitToken(id, token) {
      const url = "https://red-dev.qrcom.app/api/user/setToken";
      const data = { userId: id, token: token };

      console.log("<QRCOM> App: Submit current token to backend")
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.text())
        .then((text) => {
          console.log("QRCOM: Backend response");
          console.log(JSON.parse(text));
        })
        .catch((err) => console.error(`<QRCOM> App: Token submiting error (${err})`));
    },
    stopStreaming() {
      if (tracks) {
        console.log(tracks)
        tracks.forEach((track) => {
          track.stop();
        });
      }
    },
    closeConnection() {
      if (currentConnection) {
        currentConnection.close();
        currentConnection = null;
      }
      if (currentCall) {
        // currentCall.close();
        currentCall = null;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.user {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__section {
    padding: 0 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: black;
    z-index: 100;
  }

  &__text {
    margin-bottom: 16px;
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__buttons {
    width: 100%;
    padding: 0 15%;
    position: absolute;
    bottom: 15%;
    display: flex;
    justify-content: space-between;
  }

  &__button-green {
    background-color: green;
  }

  &__button-blue {
    background-color: blue;
  }

  &__button-red {
    background-color: red;
  }
}
</style>
