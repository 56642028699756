<template>
  <div>
    <nav class="nav">
      <a class="nav__item" href="">
        <font-awesome-icon icon="fa-solid fa-key" />
        <span>Доступ</span>
      </a>
      <a class="nav__item" href="">
        <font-awesome-icon icon="fa-solid fa-qrcode" />
        <span>Домофон</span>
      </a>
      <a class="nav__item" href="">
        <font-awesome-icon icon="fa-solid fa-house-user" />
        <span>Аккаунт</span>
      </a>
      <a class="nav__item" href="" @click="exit()">
        <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
        <span>Выход</span>
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  methods: {
    exit() {
      localStorage.clear();
      if (typeof FirebasePlugin !== "undefined") {
        FirebasePlugin.unregister();
      }
      this.$router.push("/");
    }
  }
}
</script>

<style lang="less" scoped>
@import url("@/assets/styles/vars.less");

// @color-border: #aaaaaa;
// @color-primary: #2eb85c;
.nav {
  height: 64px;
  width: 100%;
  display: flex;
  border-top: solid 2px @color-primary;
  background-color: @color-secondary;

  &__item {
    width: 25%;
    height: 100%;
    padding: 8px;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    &>span {
      font-size: 16px;
    }
  }
}
</style>