<template>
  <qrcode-stream @decode="decode" />
</template>

<script>
import { QrcodeStream } from "vue3-qrcode-reader";
export default {
  name: "Scanner",
  components: { QrcodeStream },
  methods: {
    decode(decodeString) {
      const data = JSON.parse(decodeString);
      console.log(`SCANED DATA: ${data}`);
      if (data.type == "qrcom") {
        localStorage.setItem("currentSite", decodeString);
        this.$router.push("guest");
      }
    },
  },
};
</script>

<style scoped></style>
