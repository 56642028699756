import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { 
  faQrcode,
  faRightFromBracket,
  faKey,
  faHouseUser,
  faDeleteLeft,
  faPhone
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
  faQrcode,
  faRightFromBracket,
  faKey,
  faHouseUser,
  faDeleteLeft,
  faPhone
  );


// let isBrowser =
//   document.URL.indexOf("http://") !== -1 ||
//   document.URL.indexOf("https://") !== -1;
// if (!isBrowser) {
//   console.log("APP ENVIRONMENT: CORDOVA");
//   document.addEventListener("deviceready", init, false);
// } else {
//   console.log("APP ENVIRONMENT: BROWSER");
//   init();
// }
init();

function init() {
  createApp(App)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount("#app");
}
