<template>
  <div class="wrapper">
    <Version />
    <router-view />
  </div>
</template>

<script>
import Version from "@/components/Version.vue";
export default {
  components: {
    Version
  },
  mounted() {
    document.addEventListener("deviceready", this.checkPermissions, false)
  },
  methods: {
    isCordova() {
      if (window.hasOwnProperty("cordova")) {
        return true
      };
      return false;
    },
    checkPermissions() {
      if (this.isCordova()) {
        console.log("<QRCOM> Cordova: Check permissions");
        var permissions = cordova.plugins.permissions;
        var list = [
          permissions.CAMERA,
          permissions.RECORD_AUDIO,
          permissions.ACCESS_FINE_LOCATION,
        ];
        // TODO: hasPermission alternative
        permissions.hasPermission(
          list,
          (status) => {
            if (!status.hasPermission) {
              permissions.requestPermissions(
                list,
                (status) => {
                  if (!status.hasPermission) console.log("<QRCOM> Cordova: Permission error");
                },
                () => console.log("<QRCOM> Cordova: Permission error")
              );
            }
          },
          null
        );
      } else console.log("<QRCOM> App: Running in browser, can't check permissions");
    },
  }
};
</script>

<style lang="less">
@import url("@/assets/styles/vars.less");
// @color-border: #aaaaaa;
// @color-primary: #2eb85c;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  display: flex;
  justify-content: center;
}

.wrapper {
  min-width: 320px;
  max-width: 390px;
  min-height: 660px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.input {
  width: 100%;
  padding: 8px 16px;
  font-size: 32px;
  outline: none;
  border-radius: 16px;
  border: solid 2px @color-primary;
  background-color: @color-secondary;
}

.button {
  width: 100%;
  padding: 8px;
  font-size: 32px;
  background-color: @color-primary;
  color: white;
  border-radius: 16px;
  border: none;
  outline: none;
  &:active {
    background-color: @color-secondary;
    color: @color-primary;
  }
  transition: all 250ms linear;
}


.button-round {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: none;
}

.text {
  font-size: 24px;
  text-align: center;
}

.error {
  font-size: 24px;
  text-align: center;
  color: @color-accent;
}
</style>
